import { AppSettings } from '@wix/search-settings-client';

import { IWidgetProps } from '../Widget';

export const isProductCollectionsFacetVisible = ({
  collections,
  filter,
}: IWidgetProps['productFacets']): boolean => {
  return !!collections.length || !!filter.collections?.length;
};
