import React from 'react';
import { isEqual } from 'lodash';

import { SearchDocumentType, ISearchDocument } from '@wix/client-search-sdk';
import {
  withEnvironment,
  InjectedEnvironmentProps,
  InjectedErrorMonitorProps,
  InjectedBiLoggerProps,
  InjectedExperimentsProps,
  withTranslation,
  WithTranslation,
  withBi,
  withErrorMonitor,
  withExperiments,
} from '@wix/yoshi-flow-editor';
import { wixSearchBarClickX } from '@wix/bi-logger-wix-search-widget/v2';
import {
  withSettings,
  WithSettingsProps,
} from '@wix/yoshi-flow-editor/tpa-settings/react';

import { GridLayout } from '../GridLayout';
import { ListLayout } from '../ListLayout';
import { SampleLayout } from '../SampleLayout';
import { EventList } from '../EventList';

import { SearchResults } from '../SearchResults';

import { IWidgetProps, IWidgetState } from './Widget.types';
import {
  SearchRequestStatus,
  ITab,
  DocumentTypeChangeSource,
} from '../../types/types';

import { DocumentClickOrigin } from '../../platform/searchResultsControllerStore';
import {
  buildSearchResultsUrl,
  toLocationSearchRequest,
} from '../../../../../lib/location';
import { Spec } from '../../../../../lib/specs';
import { getTotalPages } from '../../platform/pagination';
import {
  getDateFormatter,
  getTimeFormatter,
  getCurrencyFormatter,
} from '../../lib/formatters/get-formatters';
import {
  DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT,
  DOCUMENT_TYPE_TRANSLATIONS,
  DOCUMENT_TYPE_ACCESSIBILITY_LABEL,
} from '../documentTypeTranslations';
import { IListLayoutProps, ISampleGroup } from '../Layout.types';
import { ILayoutItem } from '../LayoutItem.types';
import {
  ProductFacets,
  isProductCollectionsFacetVisible,
} from '../ProductFacets';
import settingsParams from '../../../settingsParams';
import { ProductFacetsEmptyMessage } from '../ProductFacetsEmptyMessage';
import { withAppSettings, InjectedAppSettingsProps } from '../../hocs';
import { ScrollToWidget } from '../../../../../lib/scrollToWidget';

export class WidgetComponent extends React.Component<
  WithTranslation &
    WithSettingsProps &
    IWidgetProps &
    InjectedEnvironmentProps &
    InjectedBiLoggerProps &
    InjectedExperimentsProps &
    InjectedErrorMonitorProps &
    InjectedAppSettingsProps,
  IWidgetState
> {
  state = {
    searchQuery: this.props.searchRequest.query,
  };

  listRef: React.RefObject<
    HTMLUListElement & HTMLDivElement
  > = React.createRef();
  searchResultsRef: React.RefObject<HTMLDivElement> = React.createRef();

  getTabTitleWithCount = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const tabSettings = this.props.appSettings.categoryList[documentType];
    const titleOverride = tabSettings.useOverride && tabSettings.override;

    if (titleOverride) {
      return `${titleOverride} (${count})`;
    }

    const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS_WITH_COUNT[documentType];
    return titleI18NKey
      ? this.props.t(titleI18NKey, {
          number: count,
        })
      : documentType;
  };

  getTabTitleWithoutCount = (documentType: SearchDocumentType): string => {
    const tabSettings = this.props.appSettings.categoryList[documentType];
    const titleOverride = tabSettings.useOverride && tabSettings.override;
    if (titleOverride) {
      return titleOverride;
    }

    const titleI18NKey = DOCUMENT_TYPE_TRANSLATIONS[documentType];
    return titleI18NKey ? this.props.t(titleI18NKey) : documentType;
  };

  getAccessibilityLabelForDocumentType = (
    documentType: SearchDocumentType,
    count: number,
  ): string => {
    const { t } = this.props;
    let title = documentType;

    const titleI18NKey = DOCUMENT_TYPE_ACCESSIBILITY_LABEL[documentType];

    if (titleI18NKey) {
      title = t(titleI18NKey, {
        number: count,
      });
    }

    return title;
  };

  createTab = (documentType: SearchDocumentType): ITab => {
    const { searchResponseTotals } = this.props;
    const count = searchResponseTotals?.[documentType];

    return {
      documentType,
      title: count
        ? this.getTabTitleWithCount(documentType, count)
        : this.getTabTitleWithoutCount(documentType),
      count: count || 0,
    };
  };

  componentDidMount() {
    if (this.props.scrollToWidget) {
      this.scrollToWidget(
        this.props.scrollToWidget === ScrollToWidget.CheckViewportAndScroll,
        true,
      );
    }
  }

  componentDidUpdate(prevProps: IWidgetProps) {
    const { scrollToWidget } = this.props;

    // TODO: review viewMode when resolved https://github.com/wix-private/native-components-infra/pull/28
    if (
      prevProps.searchRequest.query !== this.props.searchRequest.query ||
      prevProps.viewMode !== this.props.viewMode
    ) {
      this.setState({
        searchQuery: this.props.searchRequest.query,
      });
    }

    if (prevProps.scrollToWidget !== scrollToWidget && scrollToWidget) {
      this.scrollToWidget(
        scrollToWidget === ScrollToWidget.CheckViewportAndScroll,
      );
    }

    if (
      this.listRef.current &&
      !isEqual(prevProps.searchRequest, this.props.searchRequest)
    ) {
      // safari (as of 2019-11-18) ignores `preventScroll`:
      // https://bugs.webkit.org/show_bug.cgi?id=178583
      const safariCantPreventScrollX = window.scrollX;
      const safariCantPreventScrollY = window.scrollY;

      this.listRef.current.focus({
        preventScroll: true,
      });

      if (
        safariCantPreventScrollX !== window.scrollX ||
        safariCantPreventScrollY !== window.scrollY
      ) {
        window.scrollTo(safariCantPreventScrollX, safariCantPreventScrollY);
      }
    }
  }

  isWidgetInViewport(isMount?: boolean) {
    if (this.searchResultsRef.current) {
      let distanceToViewportTop = this.searchResultsRef.current?.getBoundingClientRect()
        .top;

      // After navigation to Search Results scroll to top happens
      // But it does not always happen before widget scrollTo
      // This changes calculation as if page is scrolled to top
      if (isMount) {
        distanceToViewportTop += window.scrollY;
      }

      return (
        distanceToViewportTop > 0 && distanceToViewportTop < window.innerHeight
      );
    }

    return false;
  }

  scrollToWidget(checkViewport: boolean, isMount?: boolean) {
    const shouldScroll =
      this.props.experiments.enabled(Spec.ScrollToWidget) &&
      !this.props.environment.isEditorX &&
      (!checkViewport || !this.isWidgetInViewport(isMount));

    if (shouldScroll) {
      this.props.host.scrollTo?.();
    }

    this.props.onScrollToWidget();
  }

  handleQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchQuery: e.target.value });
  };

  handleQueryClear = () => {
    const { bi, isDemoContent } = this.props;
    bi.report(wixSearchBarClickX({ isDemo: isDemoContent }));
    this.setState({ searchQuery: '' }, () => {
      this.handleSubmit();
    });
  };

  handlePageChange = ({
    event,
    page,
  }: {
    event: React.MouseEvent<HTMLElement>;
    page: number;
  }) => {
    event.stopPropagation();

    if (shouldKeepDefaultBehavior(event)) {
      return;
    }

    event.preventDefault();

    const { onPageChange } = this.props;

    onPageChange(page);
  };

  handleTabChange = (tabs: (ITab & { title: string })[], tabIndex: number) => {
    const { onDocumentTypeChange } = this.props;
    onDocumentTypeChange(
      tabs[tabIndex].documentType,
      DocumentTypeChangeSource.Tab,
    );
  };

  handleSamplesViewAllClick = (
    e: React.MouseEvent<HTMLElement>,
    documentType: SearchDocumentType,
  ) => {
    e.stopPropagation();

    if (shouldKeepDefaultBehavior(e)) {
      return;
    }

    e.preventDefault();

    const { onDocumentTypeChange } = this.props;

    onDocumentTypeChange(documentType, DocumentTypeChangeSource.ViewAllButton);
  };

  handleSubmit = () => {
    const { onQuerySubmit } = this.props;
    const { searchQuery } = this.state;

    onQuerySubmit(searchQuery);
  };

  handleSearchResultClick = (
    e: React.MouseEvent<HTMLElement>,
    item: ISearchDocument,
    index: number,
    clickOrigin: DocumentClickOrigin,
  ) => {
    if (shouldKeepDefaultBehavior(e)) {
      e.stopPropagation();
      return;
    }
    e.preventDefault();

    this.props.onDocumentClick(item, index, clickOrigin);
  };

  buildPageUrl = (page: number): string => {
    const locationRequest = {
      ...toLocationSearchRequest(this.props.searchRequest),
      page,
    };

    return buildSearchResultsUrl(
      this.props.searchResultsAbsoluteUrl,
      locationRequest,
      this.props.locationQuery,
    );
  };

  buildDocuments = (documents: ILayoutItem[]) => {
    const { isDemoContent, t } = this.props;

    return isDemoContent
      ? documents.map((document) => {
          return Object.entries(document).reduce<ILayoutItem>(
            (acc, [key, value]) => {
              (acc as Record<string, any>)[key] =
                typeof value === 'string' &&
                value.startsWith('mockedSearchResults.')
                  ? t(value)
                  : value;
              return acc;
            },
            {} as ILayoutItem,
          );
        })
      : documents;
  };

  buildItems = (items: ISampleGroup[]) => {
    const { isDemoContent } = this.props;

    return isDemoContent
      ? items.map((item) => ({
          ...item,
          documents: this.buildDocuments(item.documents),
        }))
      : items;
  };

  getVisibleTabsWithTitles = (): ITab[] => {
    const { visibleDocumentTypes } = this.props;
    return visibleDocumentTypes.map(this.createTab);
  };

  getActiveTabIndex = (visibleTabsWithTitles: ITab[]) => {
    const { searchRequest } = this.props;
    const index: number =
      visibleTabsWithTitles.findIndex(
        (tab) => tab.documentType === searchRequest.documentType,
      ) || 0;
    return index >= 0 ? index : 0;
  };

  getResultCount = (visibleTabsWithTitles: ITab[]): number => {
    const { searchResponse, searchRequest } = this.props;

    return searchRequest.documentType === SearchDocumentType.All
      ? (visibleTabsWithTitles.length > 0 &&
          visibleTabsWithTitles[this.getActiveTabIndex(visibleTabsWithTitles)]
            .count) ||
          0
      : searchResponse.totalResults;
  };

  getTotalCount = (visibleTabsWithTitles: ITab[]) => {
    return visibleTabsWithTitles.length > 0
      ? visibleTabsWithTitles[this.getActiveTabIndex(visibleTabsWithTitles)]
          .count
      : 0;
  };

  getSamplesWithTitles = () => {
    const {
      searchSamples,
      visibleDocumentTypes,
      searchResponseTotals,
    } = this.props;
    return searchSamples
      .filter((sample) => visibleDocumentTypes.includes(sample.documentType))
      .map((s) => ({
        ...s,
        accessibilityLabel: searchResponseTotals
          ? this.getAccessibilityLabelForDocumentType(
              s.documentType,
              searchResponseTotals[s.documentType] || 0,
            )
          : this.getTabTitleWithoutCount(s.documentType),
        title: searchResponseTotals
          ? this.getTabTitleWithCount(
              s.documentType,
              searchResponseTotals[s.documentType] || 0,
            )
          : this.getTabTitleWithoutCount(s.documentType),
      }));
  };

  render() {
    const {
      appSettings,
      experiments,
      isDemoContent,
      locale,
      currency,
      errorMonitor,
      searchRequest,
      searchRequestStatus,
      searchResponse,
      searchResponseTotals,
      settings,
      t,
      apiErrorDetails,
      productFacets,
      selectedSortOption,
      onProductFacetsFilterChange,
      onProductFacetsFilterReset,
      onSortChange,
      host,
    } = this.props;

    const { categoryList, isProductPriceVisible } = appSettings;
    const { searchQuery } = this.state;

    // TODO: remove when the widget starts to use SSR-rendered screen instead of loading indicator
    if (!categoryList) {
      return <div />;
    }

    const totalPages = getTotalPages(
      searchRequest.paging.pageSize,
      searchResponse.totalResults,
    );

    const visibleTabsWithTitles = this.getVisibleTabsWithTitles();
    const activeTabIndex = this.getActiveTabIndex(visibleTabsWithTitles);
    const selectedDocumentType =
      visibleTabsWithTitles[activeTabIndex]?.documentType ??
      SearchDocumentType.All;

    const totalCount = visibleTabsWithTitles
      .filter((tab) => tab.documentType !== SearchDocumentType.All)
      .reduce((total, tab) => {
        return total + tab.count;
      }, 0);

    const allTab = visibleTabsWithTitles.find(
      (tab) => tab.documentType === SearchDocumentType.All,
    );

    if (allTab) {
      allTab.title =
        totalCount > 0
          ? this.getTabTitleWithCount(SearchDocumentType.All, totalCount)
          : this.getTabTitleWithoutCount(SearchDocumentType.All);
    }

    const shouldRenderSamples =
      searchRequest.documentType === SearchDocumentType.All;

    const samplesWithTitles = shouldRenderSamples
      ? this.getSamplesWithTitles()
      : [];

    const documentType = searchRequest.documentType || SearchDocumentType.All;
    const items = this.buildDocuments(searchResponse.documents);

    const listProps: IListLayoutProps = {
      formatCurrency: getCurrencyFormatter(locale, errorMonitor),
      formatDate: getDateFormatter(locale, errorMonitor),
      formatTime: getTimeFormatter(locale, errorMonitor),
      items,
      listRef: this.listRef,
      onItemLinkClick: this.handleSearchResultClick,
      onProductAddToCart: this.props.onProductAddToCart,
      label: searchResponseTotals
        ? t(DOCUMENT_TYPE_ACCESSIBILITY_LABEL[documentType], {
            number: searchResponseTotals[documentType],
          })
        : t(DOCUMENT_TYPE_TRANSLATIONS[documentType]),
      t,
    };

    const failed = searchRequestStatus === SearchRequestStatus.Failed;

    const hasAnyProducts = !!searchResponseTotals?.[
      SearchDocumentType.Products
    ];

    const hasAnyProductFacets =
      isProductCollectionsFacetVisible(productFacets) || isProductPriceVisible;

    const shouldShowProductFacets =
      !failed && productFacets.enabled && hasAnyProducts && hasAnyProductFacets;

    return (
      <SearchResults
        searchResultsRef={this.searchResultsRef}
        searchQuery={
          isDemoContent ? t('resultsFoundMessage.demoQuery') : searchQuery
        }
        searchPlaceholder={settings.get(settingsParams.searchBarPlaceholder)}
        searchClearLabel={t('searchResults.clearLabel')}
        isSearchBarEnabled={settings.get(settingsParams.isSearchBarEnabled)}
        activeTabIndex={activeTabIndex}
        selectedDocumentType={selectedDocumentType}
        onTabChange={(tabIndex: number) =>
          this.handleTabChange(visibleTabsWithTitles, tabIndex)
        }
        onQueryChange={this.handleQueryChange}
        onQueryClear={this.handleQueryClear}
        onSubmit={this.handleSubmit}
        onProductFacetsFilterReset={onProductFacetsFilterReset}
        isPaginationHidden={shouldRenderSamples}
        currentPage={searchRequest.paging.page}
        totalPages={totalPages > 1 ? totalPages : null}
        onPageChange={this.handlePageChange}
        isLoading={searchRequestStatus === SearchRequestStatus.Loading}
        isDemoContent={isDemoContent}
        resultsCount={this.getResultCount(visibleTabsWithTitles)}
        totalCount={this.getTotalCount(visibleTabsWithTitles)}
        demoContentNotificationText={t(
          'searchResults.demoContentNotificationText',
        )}
        buildPageUrl={this.buildPageUrl}
        selectedSortOption={selectedSortOption}
        onSortChange={onSortChange}
        visibleTabsWithTitles={visibleTabsWithTitles}
        lastQuery={this.props.searchRequest.query}
        failed={failed}
        apiErrorDetails={apiErrorDetails}
        facets={
          shouldShowProductFacets ? (
            <ProductFacets
              facets={productFacets}
              onFacetsFilterChange={onProductFacetsFilterChange}
              locale={locale}
              currency={currency!}
            />
          ) : undefined
        }
        host={host}
      >
        {(() => {
          switch (documentType) {
            case SearchDocumentType.All:
              return (
                <SampleLayout
                  {...listProps}
                  results={this.buildItems(samplesWithTitles)}
                  onViewAllClick={this.handleSamplesViewAllClick}
                />
              );
            case SearchDocumentType.Products:
              return shouldShowProductFacets && items.length === 0 ? (
                <ProductFacetsEmptyMessage
                  onProductFacetsFilterReset={onProductFacetsFilterReset}
                />
              ) : (
                <GridLayout {...listProps} />
              );
            case SearchDocumentType.Events:
              return <EventList {...listProps} />;
            default:
              return <ListLayout {...listProps} />;
          }
        })()}
      </SearchResults>
    );
  }
}

export const Widget = withAppSettings(
  withErrorMonitor(
    withExperiments(
      withSettings(withBi(withEnvironment(withTranslation()(WidgetComponent)))),
    ),
  ),
);

function isAnchorElement(element: HTMLElement): element is HTMLAnchorElement {
  return element.nodeName === 'A';
}

// handle opening links in a new tab
// https://github.com/zeit/next.js/blob/42771f6451df6ba2dbda51e60968e9edcfde74af/packages/next/client/link.tsx#L149-L159
function shouldKeepDefaultBehavior(e: React.MouseEvent<HTMLElement>): boolean {
  const currentTarget = e.currentTarget;
  return (
    isAnchorElement(currentTarget) &&
    ((currentTarget.target && currentTarget.target !== '_self') ||
      e.metaKey ||
      e.ctrlKey ||
      e.shiftKey ||
      (e.nativeEvent && e.nativeEvent.button === 1))
  );
}
